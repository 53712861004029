import React, { useEffect, useState } from 'react'
import Navbar from '../Common/Navbar';
import { useLocation } from 'react-router-dom';
import ReportsComponent from '../Reports/Reports';
import '../../index.css';
import Tabs from '../Common/Tabs';
import FaultTable from './FaultsTable';
import DonutChart from './Donutchart';
import Popup from '../Popups/AlertPopup'
import { activeTabForStatus } from '../Common/Constants';
import { fetchActualFaultsData, fetchLocomotiveData, fetchPredictedAlertsData,fetchPopupAlert } from '../../Utils/Api';
import { sessionStorageGet, sessionStorageSet } from '../Common/SessionStorageHandler';

function DashboardComponent() {
    const [activeTab, setActiveTab] = useState(activeTabForStatus);
    const [accordionOpen, setAccordionOpen] = useState({
        predictedAlerts: false,
        actualFaults: false
    });
    const [mobileArrowVisible, setMobileArrowVisible] = useState(false);
    const [closedSlides, setClosedSlides] = useState([]);
    const [error,setError] = useState(false)
    const [predictedAlertsData, setPredictedAlertsData] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [acutalFaultsData, setAcutalFaultsData] = useState({});
    const [latestAlerts,setLatestAlerts] = useState([])
    const [locomotiveData, setLocomotiveData] = useState({
        locomotives: 0,
        active: 0,
        inactive: 0,
    });
    const location = useLocation();
    const screenSize = 990
    const intervalDuration = process.env.REACT_APP_DATA_FETCH_INTERVAL
    const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth <= screenSize);
    const [isOnline, setIsOnline] = useState(true);
    const getLocalScreenRefreshCount = () => {
        const count = sessionStorage.getItem('localScreenRefreshCount');
        return count ? parseInt(count, 10) : 0;
    };
    const [localScreenRefreshCount, setLocalScreenRefreshCount] = useState(getLocalScreenRefreshCount);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth <= screenSize);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
 
    useEffect(() => {
        if (location.state?.activeTab) {
            setActiveTab(location.state.activeTab);
        }
    }, [location.state]);

    const handleAccordionToggle = (tableName) => {
        setAccordionOpen(prevState => ({
            ...prevState,
            [tableName]: !prevState[tableName]
        }));
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    useEffect(() => {
        setMobileArrowVisible(isMobileScreen);
    }, [isMobileScreen]);
 
    useEffect(() => {
        const addClassesOnPriority = () => {
            const statusTags = document.getElementsByClassName("status");
            for (let i = 0; i < statusTags.length; i++) {
                if (statusTags[i].textContent.trim() === "Critical") {
                    statusTags[i].classList.add("critical");
                } if (statusTags[i].textContent.trim() === "Minor") {
                    statusTags[i].classList.add("minor");
                }
            }
        };
 
        addClassesOnPriority();

        if (accordionOpen.predictedAlerts || accordionOpen.actualFaults && isMobileScreen) {
            addClassesOnPriority();
        }
    }, [accordionOpen, isMobileScreen]);

    const checkOnlineStatus = async () => {
        try {
            const responses = await Promise.all([
                fetchLocomotiveData(),
                fetchPredictedAlertsData(),    
                fetchActualFaultsData(),
                fetchPopupAlert()
            ])
 
            const allSuccessful = responses.every(response => {
                return response.status >= 200 && response.status < 300;
            });
            if (allSuccessful) {
                var backUpData = {
                    locomotiveData: responses[0].data,
                    predictedAlertsData: responses[1].data,
                    acutalFaultsData: responses[2].data
                }
                sessionStorageSet("BackupData", backUpData);
 
                setLocomotiveData({
                    locomotives: responses[0].data['Total Locomotives'],
                    active: responses[0].data.Count.Active,
                    inactive: responses[0].data.Count.Inactive,
                });
                setPredictedAlertsData(responses[1].data);
                setAcutalFaultsData(responses[2].data);
 
                setLocalScreenRefreshCount(prev => {
                    const newCount = prev + 1;
                    sessionStorage.setItem('localScreenRefreshCount', newCount);
                    return newCount;
                });
            }
            setIsOnline(allSuccessful);
            setError(false)
        } catch (error) {
            setIsOnline(false);
            if (localScreenRefreshCount > 0) {
                let backUpData = sessionStorageGet("BackupData")
                const { locomotiveData, predictedAlertsData, acutalFaultsData } = backUpData
                setLocomotiveData({
                    locomotives: locomotiveData['Total Locomotives'],
                    active: locomotiveData.Count.Active,
                    inactive: locomotiveData.Count.Inactive,
                });
                setPredictedAlertsData(predictedAlertsData);
                setAcutalFaultsData(acutalFaultsData);
            }
            else {
                setError(true)
            }
        }
    };
 
    useEffect(() => {
        checkOnlineStatus();
        const intervalId = setInterval(checkOnlineStatus, intervalDuration);
 
        return () => clearInterval(intervalId);
    }, []);

    const fetchAlerts = async () => {
        try {
            const response = await fetchPopupAlert();
            const alerts = response.data || [];
            const filteredAlerts = alerts.filter(alert => !closedSlides.includes(alert.PredictedAlertId));
            if (filteredAlerts.length > 0 && activeTab === 'dashboard') {
                setLatestAlerts(filteredAlerts);
                setShowPopup(true);
            } else {
                setShowPopup(false);
            }
        } catch (error) {
            console.error('Error fetching alerts:', error);
        }
    };

    useEffect(() => {
        fetchAlerts();
    }, [])

    useEffect(() => {
        var intervalId;
            intervalId = setInterval(fetchAlerts, intervalDuration);

        return () => clearInterval(intervalId);
    }, [closedSlides]);

    const handlePopupClose = (alertId) => {
        setClosedSlides(prev => [...prev, alertId]);
        setShowPopup(false);
    };

    const trendValue = predictedAlertsData["Predicted Alerts"]?.["Trend"] || 0;

    return (
        <div>
            <Navbar />
            {!error ?(
                <div>
            {activeTab === activeTabForStatus && (
                <div className='status-response'>
                    {isOnline ? (
                        <button className='status-btn'>
                            <p>Online</p>
                        </button>
                    ) : (
                        <button className='status-btn offline-status-btn'>
                            <p>Offline</p>
                        </button>
                    )}
                </div>
            )}
            <Tabs activeTab={activeTab} handleTabClick={setActiveTab} />
            <div id="dashboard-data" className={activeTab === 'dashboard' ? 'tab-content' : 'tab-content hidden'}>
                <div>
                    <ul className="locomotive-action-list list-flex">
                        <li>
                            <h3>Locomotives</h3>
                            <div>
                                <span className="locomotive-number">{locomotiveData.locomotives}</span>
                            </div>
                        </li>
                        <li>
                            <h3>Issues Predicted</h3>
                            <div className="card-flex">
                                <span className="locomotive-number">{predictedAlertsData["Predicted Alerts"]?.["Total Alerts"] || 0}</span>
                                <span className="defect-percentage">
                                    <div className='card-flex'>
                                        <div>{trendValue}</div>
                                        <div className='index'>
                                            <img
                                                src={parseFloat(String(trendValue).replace('%', '')) < 0 ? "assets/decrease-index.png" : "assets/increase-index.png"}
                                                alt={parseFloat(String(trendValue).replace('%', '')) < 0 ? "decrease-index" : "increase-index"}
                                            />
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </li>
                        <li>
                            <h3>Active</h3>
                            <div className="card-flex">
                                <span className="locomotive-number">{locomotiveData.active}</span>
                            </div>
                        </li>
                        <li>
                            <h3>In Active</h3>
                            <div className="card-flex">
                                <span className="locomotive-number">{locomotiveData.inactive}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="in-flex">
                    <div className='alerts'>
                        <DonutChart
                            title="Predicted Alerts"
                            total={predictedAlertsData["Predicted Alerts"]?.["Total Alerts"] || 0}
                            parameters={predictedAlertsData["Predicted Alerts"]?.["Signal Name"] || []}
                            containerClass="predicted-alerts donut-chart"
                            dataClass="alerts-data place-center"
                            alertCounts={predictedAlertsData["Predicted Alerts"]?.["Count of Alerts"] || []}
                        />
                        <FaultTable
                            title={"Predicted Alerts"}
                            predictedAlertsDetails={predictedAlertsData["Predicted Alerts Details"] || []}
                            toggleAccordion={() => handleAccordionToggle('predictedAlerts')}
                            accordionOpen={accordionOpen.predictedAlerts}
                            isMobileScreen={isMobileScreen}
                            mobileArrowVisible={mobileArrowVisible}
                            showActionColumn={true}
                            timeProperty="AlertTime"
                        />
                    </div>
                    <div className='alerts'>
                        <DonutChart
                            title="Actual Faults"
                            total={acutalFaultsData["Faults Occured"]?.["Total Faults"] || 0}
                            parameters={acutalFaultsData["Faults Occured"]?.["Signal Name"] || []}
                            containerClass="actual-faults donut-chart"
                            dataClass="actual-faults-data place-center"
                            alertCounts={acutalFaultsData["Faults Occured"]?.["Count of Faults"] || []}
                        />
                        <FaultTable
                            title={"Actual Faults"}
                            predictedAlertsDetails={acutalFaultsData["Faults Occured Details"] || []}
                            toggleAccordion={() => handleAccordionToggle('actualFaults')}
                            accordionOpen={accordionOpen.actualFaults}
                            isMobileScreen={isMobileScreen}
                            mobileArrowVisible={mobileArrowVisible}
                            showActionColumn={false}
                            timeProperty="FaultTime"
                        />
                    </div>
                </div>
            </div>
            <ReportsComponent activeTab={activeTab} />
     
            {activeTab === 'dashboard' && showPopup && isOnline && (
                <Popup show={showPopup} onClose={handlePopupClose}  latestAlerts={latestAlerts} closedSlides={closedSlides} />
            )}
        </div>
          ):(
            <div className='popup-modal'>
                <div className='error-popup-modal'>
                    <img src="assets/error-img.png" alt="" />
                    <h4>An error has occured</h4>
                    <p>Something went wrong... Please try again later.</p>
                </div>
            </div>
        )}
       
    </div>
    )
}
export default DashboardComponent;
